<template>
  <div>
    <div class="nav nav-pills">
      <router-link
        to="/zap/order-zap-list?type=inwork"
        exact
        class="nav-link"
        active-class="active"
        >в работе</router-link
      >
      <router-link
        to="/zap/order-zap-list?type=send"
        exact
        class="nav-link"
        active-class="active"
        >отгружен</router-link
      >
      <router-link
        to="/zap/order-zap-list?type"
        exact
        class="nav-link"
        active-class="active"
        >все</router-link
      >
    </div>
    <div
      v-if="!$route.query.type || $route.query.type == 'send'"
      class="w-auto p-2"
    >
      <select
        class="form-control form-control-sm shadow-none col-md-2 col-sm-2 d-inline m-1"
        v-model="change_order_count"
        @change="change_filter"
      >
        <option value="100">последние 100</option>
        <option value="all">все</option>
      </select>
      <input
        class="form-control form-control-sm shadow-none d-inline m-1 col-md-2 col-sm-2"
        type="text"
        placeholder="поиск по названию СЦ"
        v-model.trim="filter_text"
        v-if="USER_INFO.status === 'admin'"
      />
      <button
        class="btn btn-sm btn-outline-primary shadow-none d-inline m-1"
        @click="change_filter"
        v-if="USER_INFO.status === 'admin'"
      >
        фильтровать
      </button>
    </div>
    <div class="col-md-12">
      <div class="table-wrapper">
        <table class="table table-sm">
          <tr>
            <th></th>
            <th>№</th>
            <th v-if="USER_INFO.status === 'admin'">СЦ</th>
            <th>дата создания</th>
            <th>комментарий</th>
            <th></th>
            <th
              v-if="
                USER_INFO.status === 'admin' && $route.query.type === 'inwork'
              "
            >
              подтвержден
            </th>
            <th>статус</th>
          </tr>
          <tbody v-if="orderList && orderList.length">
            <tr
              v-for="(item, idx) in orderList"
              :key="idx"
              class="cursor"
              :class="{
                'bg-success':
                  $route.query.type === 'inwork' &&
                  item.order_confirm_user &&
                  USER_INFO.status === 'admin',
                'bg-warning':
                  $route.query.type === 'inwork' &&
                  !item.order_confirm_user &&
                  USER_INFO.status === 'admin' &&
                  item.status === 'в работе' &&
                  item.files
              }"
            >
              <td>{{ idx + 1 }}</td>
              <td @click="goOrder(item.id)">{{ item.id }}</td>
              <td v-if="USER_INFO.status === 'admin'" @click="goOrder(item.id)">
                {{ item.user }}
              </td>
              <td @click="goOrder(item.id)" class="text-nowrap">
                {{ item.date_add }}
              </td>
              <td @click="goOrder(item.id)">{{ item.comment }}</td>
              <td :title="item.cdek_id && 'номер отслеживания СДЭК'">
                <a
                  :href="
                    `https://www.cdek.ru/ru/tracking?order_id=${item.cdek_id}`
                  "
                  target="_blanc"
                  >{{ item.cdek_id }}</a
                >
              </td>
              <td
                v-if="
                  USER_INFO.status === 'admin' && $route.query.type === 'inwork'
                "
              >
                {{ item.date_order_confirm_user }}
              </td>
              <td @click="goOrder(item.id)">{{ item.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "../../api";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "OrderZapList",
  watch: {
    $route: function() {
      this.get_data();
    }
  },
  computed: {
    ...mapGetters(["USER_INFO", "ZAPORDERCOUNT", "ZAPOFILTERORDERTEXT"]),
    change_order_count: {
      get() {
        return this.ZAPORDERCOUNT;
      },
      set(value) {
        this.set_zap_order_count(value);
      }
    },
    filter_text: {
      get() {
        return this.ZAPOFILTERORDERTEXT;
      },
      set(value) {
        this.set_zap_filter_order_text(value);
      }
    }
  },
  data: () => ({
    orderList: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    ...mapMutations(["set_zap_order_count", "set_zap_filter_order_text"]),
    get_data() {
      HTTPGET({
        url: "/order_zap/get_list_order_zap.php",
        params: {
          type: this.$route.query.type,
          limit: this.ZAPORDERCOUNT,
          filter: this.ZAPOFILTERORDERTEXT.length
            ? this.ZAPOFILTERORDERTEXT
            : null
        }
      }).then(response => (this.orderList = response.data));
    },
    goOrder(id) {
      this.$router.push(`/zap/order/${id}`);
    },
    change_filter() {
      this.get_data();
    }
  }
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  overflow: auto;
  margin-top: 10px;
}
.cursor {
  cursor: pointer;
}
table {
  font-size: 0.8em;
}
.active {
  color: #fff;
  background: #007bff;
  cursor: default;
  text-decoration: none;
}
.link {
  padding: 5px;
}
.wrapper {
  padding-top: 5px;
}
td,
th {
  vertical-align: middle;
}
</style>
